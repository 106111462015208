"use client";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { ConfigProvider } from "@/context/configContext";

const queryClient = new QueryClient();

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider>{children}</ConfigProvider>
    </QueryClientProvider>
  );
}
